<template>
    <div>
        <menu-list-edit
            :is-edit-menu-sidebar-active.sync="isEditMenuSidebarActive"
        />
        <page-title></page-title>
        <!-- Table Container Card -->
        <b-card class="mt-1" no-body>
            <div class="m-1">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                        cols="6"
                    >
                        <label>{{ $t("Show") }}</label>
                        <v-select
                            v-model="perPage"
                            :clearable="false"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            class="per-page-selector d-inline-block mx-50"
                            size="sm"
                        />
                        <label>{{ $t("Entries") }}</label>
                    </b-col>

                    <b-col cols="6">
                        <b-button
                            class="float-right"
                            variant="primary"
                            @click="editMenu(null)"
                        >
                            <b-icon-cursor-fill class="mr-50" />
                            <span class="text-nowrap">{{
                                $t("Add") + $t("Menu")
                            }}</span>
                        </b-button>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refMenuListTable"
                :empty-text="$t('No Data')"
                :fields="tableColumns"
                :items="fetchMenus"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
                striped
                class="text-center position-relative"
                primary-key="menu_id"
                responsive
                show-empty
            >
                <!-- Column: Index -->
                <template #cell(index)="data">
                    <div class="text-nowrap">
                        <span class="align-text-top text-capitalize">{{
                            data.index + 1
                        }}</span>
                    </div>
                </template>

                <!-- Column: Status -->
                <template #cell(icon)="data">
                    <feather-icon
                        :icon="data.item.icon"
                        class="align-middle text-body"
                        size="16"
                    />
                </template>

                <!-- Column: Project_id -->
                <template #cell(project_id)="data">
                    <span>{{
                        data.item.project_id === 0 ? "All" : "当前"
                    }}</span>
                </template>

                <template #cell(type)="data">
                    <span v-if="data.item.type === 0" class="text-warning"
                        >目录</span
                    >
                    <span v-else-if="data.item.type === 1" class="text-info"
                        >展示</span
                    >
                    <span v-else-if="data.item.type === 2" class="text-error"
                        >管理</span
                    >
                    <span v-else-if="data.item.type === 3" class="text-primary"
                        >仪表板</span
                    >
                    <span v-else-if="data.item.type === 4" class="text-success"
                        >外链</span
                    >
                </template>

                <template #cell(title)="data">
                    <span>{{ $t(data.item.title) }}</span>
                </template>

                <template #cell(route_name)="data">
                    <span>{{ data.item.route_name || "---" }}</span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-button
                        size="sm"
                        class="btn-icon rounded-circle"
                        variant="flat-warning"
                        @click="editMenu(data.item)"
                    >
                        <b-icon-pencil-square />
                    </b-button>
                    <b-button
                        size="sm"
                        class="btn-icon rounded-circle ml-25"
                        variant="flat-danger"
                        @click="deleteMenu(data.item.menu_id)"
                    >
                        <b-icon-trash-fill />
                    </b-button>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        cols="12"
                        sm="6"
                    >
                        <span class="text-muted">{{
                            `
                          ${$t("Show")}
                          ${dataMeta.from}
                          ${$t("to")}
                          ${dataMeta.to}
                          ${$t("of")}
                          ${dataMeta.of}
                          ${$t("Entries")}
                          `
                        }}</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        cols="12"
                        sm="6"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="totalMenus"
                            hide-goto-end-buttons
                            size="sm"
                        ></b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import vSelect from "vue-select";
import useMenusList from "./useMenusList";
import MenuListEdit from "./MenusListEdit.vue";
import PageTitle from "@/views/layout/PageTitle";

export default {
    components: {
        PageTitle,
        MenuListEdit,
        vSelect,
    },
    setup() {
        const {
            isEditMenuSidebarActive,
            editMenu,
            deleteMenu,
            fetchMenus,
            tableColumns,
            perPage,
            currentPage,
            totalMenus,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refMenuListTable,
            refetchData,
        } = useMenusList();

        return {
            isEditMenuSidebarActive,
            editMenu,
            deleteMenu,
            fetchMenus,
            tableColumns,
            perPage,
            currentPage,
            totalMenus,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refMenuListTable,
            refetchData,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
//noinspection CssUnknownTarget
@import "@core/scss/vue/libs/vue-select.scss";
</style>
