import { computed, reactive, toRefs, watch } from "@vue/composition-api";
import store from "@/store";
import { showToast } from "@/libs/utils/showToast";
import { showConfirm } from "@/libs/utils/showConfirm";
import { refreshMenu } from "@/libs/utils/refreshMenu";
import { routerParams } from "@/libs/utils/routerParams";

export default function useMenusList() {
	const event = reactive({
		project_id: routerParams("project_id"),
		isEditMenuSidebarActive: false,
		refMenuListTable: null,
		perPage: 100,
		tableColumns: [
			{
				label: "#",
				key: "index",
			},
			{
				label: "导航名称",
				key: "title",
			},
			{
				label: "导航ID",
				key: "menu_id",
			},

			{
				label: "父级导航ID",
				key: "p_menu_id",
				sortable: true,
			},
			{
				label: "导航类型",
				key: "type",
				sortable: true,
			},

			{
				label: "导航图标",
				key: "icon",
			},
			{
				label: "路由",
				key: "route_name",
				sortable: true,
			},
			// {
			// 	label: "路由参数",
			// 	key: "route_params",
			// },
			{
				label: "排序",
				key: "order",
				sortable: true,
			},
			{
				label: "所属项目",
				key: "project_id",
				sortable: true,
			},
			{
				label: "操作",
				key: "actions",
			},
		],
		total: 0,
		currentPage: 1,
		perPageOptions: [10, 25, 50, 100],
		sortBy: "p_menu_id",
		isSortDirDesc: false,
		dataMeta: computed(() => {
			const localItemsCount = event.refMenuListTable ? event.refMenuListTable.localItems.length : 0;
			return {
				from: event.perPage * (event.currentPage - 1) + (localItemsCount ? 1 : 0),
				to: event.perPage * (event.currentPage - 1) + localItemsCount,
				of: event.total,
			};
		}),
	});

	const refetchData = () => {
		event.refMenuListTable.refresh();
	};

	watch([() => event.currentPage, () => event.perPage, () => event.isEditMenuSidebarActive], () => {
		if (!event.isEditMenuSidebarActive) {
			refetchData();
		}
	});

	/**
	 * 编辑导航
	 * @param menuItem
	 */
	const editMenu = (menuItem) => {
		event.isEditMenuSidebarActive = true;
		if (menuItem && menuItem.menu_id) {
			store.commit("menus/UPDATE_MENU_EDIT", { ...menuItem });
		} else {
			store.commit("menus/UPDATE_MENU_EDIT", {
				menu_id: null,
				title: "",
				icon: "",
				p_menu_id: null,
				route_name: "",
				route_params: "",
				order: 0,
			});
		}
	};
	/**
	 * 删除导航
	 * @param {*} menu_id
	 */
	const deleteMenu = (menu_id) => {
		showConfirm("是否确定要删除此导航？", () => {
			console.log("menu_id: " + menu_id);
			store
				.dispatch("menus/deleteMenu", menu_id)
				.then((response) => {
					refetchData();
					refreshMenu();
				})
				.catch((error) => {
					showToast(error);
				});
		});
	};

	/**
	 * 获取导航列表
	 * @param {*} ctx
	 * @param {*} callback
	 */
	const fetchMenus = (ctx, callback) => {
		console.log(event.project_id);
		store
			.dispatch("menus/fetchMenus", {
				project_id: event.project_id,
				pagesize: event.perPage,
				page: event.currentPage,
				sortBy: event.sortBy,
				sortDesc: event.isSortDirDesc ? 1 : 0,
			})
			.then((response) => {
				const { list, total } = response.data;
				event.total = total;
				callback(list);
			})
			.catch((error) => {
				showToast(error);
			});
	};

	return {
		...toRefs(event),
		fetchMenus,
		editMenu,
		deleteMenu,
	};
}
